<template>
    <section class="container card-extra">
        <div class="row" data-aos="fade-up">
            <div class="col-12 ">
                <div class="row ">
                    <div class="col-12 p-5">
                        <h3 class="title-subtitle text-center bold">{{$store.state.lang == 'es' ? 'Obras' : 'Work'}}</h3>
                    </div>
                    <div class="col-12 col-md-6 p-4" v-for="(item,x) in items" :key="x">
                        <router-link :to="'work/'+item.collection_slug" class="link m-auto">
                            <div class="categoria" :style="{'background-image': 'url(' +$url+'/'+item.featured + ')' }" >
                                <h3 class="subtitle">{{$store.state.lang=='es' ? item.media_es : item.media_en }}</h3>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios'

export default {
    name: 'PortfolioComponent',
    data(){
        return{
            items:{}
        }
    },
    created(){
        this.getArtworks()
    },
    methods:{
        async getArtworks(){
            await axios.get(this.$url+'/categorias-obras',{
                headers: {'Content-Type' : 'application/json'}
            })
            .then((res)=>{
                this.items = res.data.items
            }).catch((err)=>{
                console.log(err)
            })
        },
    },
    
}
</script>