var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('swiper-container',{staticClass:"mySwiper",staticStyle:{"--swiper-navigation-color":"#fff","--swiper-pagination-color":"#fff"},attrs:{"thumbs-swiper":'.mySwiper2_'+_vm.item.slug,"loop":"true","space-between":"10","navigation":"true"}},_vm._l((_vm.item.extra_images),function(image,x){return _c('swiper-slide',{key:x},[_c('img',{attrs:{"src":_vm.$url+'/'+image,"alt":_vm.item.title},on:{"click":function($event){_vm.selectedIndx=x; _vm.show()}}})])}),1),_c('swiper-container',{staticClass:"mt-5 thumnbails",class:'mySwiper2_'+_vm.item.slug,attrs:{"loop":"true","space-between":"10","slides-per-view":"3","free-mode":"true","watch-slides-progress":"true"}},_vm._l((_vm.item.extra_images),function(image,x){return _c('swiper-slide',{key:x,staticClass:"me-4"},[_c('img',{attrs:{"src":_vm.$url+'/'+image,"alt":_vm.item.title},on:{"click":function($event){_vm.selectedIndx=x}}})])}),1),_c('h3',{staticClass:"subtitle pb-0 pt-2",staticStyle:{"text-decoration":"none"}},[_c('b',[_vm._v(_vm._s(_vm.item.title))]),_vm._v(", "+_vm._s(_vm.item.year))]),_c('p',{staticStyle:{"white-space":"pre-line","text-decoration":"none"}},[_vm._v(" "+_vm._s(_vm.$store.state.lang == 'es' ? _vm.item.media_es : _vm.item.media_en)),_c('br'),_vm._v(" "+_vm._s(_vm.item.dimensions)+" ")]),_c('Transition',{attrs:{"name":"fade","appear":""}},[_c('viewer',{ref:"viewer",staticClass:"viewer",attrs:{"options":{ 
                        inline:false,
                        movable: false,
                        rotatable:false,
                        tooltip:false,
                        toolbar:false,
                        title:false,
                        scalable:false,
                        initialViewIndex: _vm.selectedIndx},"images":_vm.allimages,"rebuild":""},on:{"inited":_vm.inited},scopedSlots:_vm._u([{key:"default",fn:function(scope){return _vm._l((scope.images),function(src,indx){return _c('img',{key:indx,staticClass:"d-none",attrs:{"src":_vm.$url+'/'+src}})})}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }