<template>
    <section class="container ">
        <div class="row" data-aos="fade-up">
            <div class="col-12 image-pad">
                <Transition  name="fade" appear >
                    <div class="row">
                        <div class="col-12 p-4">
                            <h3 class="title-subtitle text-center bold">{{$store.state.lang == 'es' ? categoria.col_description_es : categoria.col_description_en}}</h3>
                        </div>
                        <div class="col-12 col-md-7 pb-5 mb-5 m-auto" v-for="(item,x) in items" :key="x" >
                            <carousel :item="item"></carousel>
                        </div>
                    </div>
                </Transition>
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios'
import 'viewerjs/dist/viewer.css'
import { component as Viewer } from "v-viewer"
import carousel from '@/components/Carousel.vue'

export default {
    name: 'GouacheCollectionComponent',
    components:{Viewer,carousel},
    data(){
        return{
            items:{},
            categoria:{}
        }
    },
    created(){
        this.getArtworks()
    },
  
    methods:{
        async getArtworks(){
            await axios.get(this.$url+'/obras-por-categoria/gouache-on-paper',{
                headers: {'Content-Type' : 'application/json'}
            })
            .then((res)=>{
                this.items = res.data.items
                this.categoria = { 'col_description_es': this.items[0].col_description_es, 'col_description_en': this.items[0].col_description_en }
            }).catch((err)=>{
                console.log(err)
            })
        },  
    },
    
}
</script>