<template>
    <div>
        <swiper-container style="--swiper-navigation-color: #fff; --swiper-pagination-color: #fff" class="mySwiper"
            :thumbs-swiper="'.mySwiper2_'+item.slug" loop="true" space-between="10" navigation="true">
            <swiper-slide v-for="(image,x) in item.extra_images" :key="x">
                <img @click="selectedIndx=x; show()" :src="$url+'/'+image" :alt="item.title"  />
            </swiper-slide>
        </swiper-container>

        <swiper-container :class="'mySwiper2_'+item.slug" class="mt-5 thumnbails" loop="true" space-between="10" slides-per-view="3" free-mode="true"
            watch-slides-progress="true">
            <swiper-slide v-for="(image,x) in item.extra_images" :key="x" class="me-4">
                <img @click="selectedIndx=x" :src="$url+'/'+image" :alt="item.title" />
            </swiper-slide>
        </swiper-container>

       
        <h3 style="text-decoration: none;" class="subtitle pb-0 pt-2"><b>{{ item.title }}</b>, {{ item.year }}</h3>
        <p style="white-space:pre-line;text-decoration: none;">
            {{ $store.state.lang == 'es' ? item.media_es : item.media_en }}<br>
            {{ item.dimensions }}
        </p>

        <Transition  name="fade" appear >
                <viewer
                    ref="viewer"
                    :options="{ 
                            inline:false,
                            movable: false,
                            rotatable:false,
                            tooltip:false,
                            toolbar:false,
                            title:false,
                            scalable:false,
                            initialViewIndex: selectedIndx}"
                    :images="allimages"
                    rebuild
                    class="viewer"
                    @inited="inited">
                    <template #default="scope">
                        <img v-for="(src,indx) in scope.images" :key="indx" :src="$url+'/'+src" class="d-none">
                    </template>
                </viewer>
            </Transition>
    </div>
</template>
  
<script>
export default {
    props:['item'],
    name: 'App',
    data(){
      return{
        allimages:[],
        selectedIndx: 0,
      }
    },
    created(){
      this.allimages = this.$props.item.extra_images
    },
    methods:{
      inited (viewer) {
          this.$viewer = viewer
      },
      show () {
          this.$viewer.show()
      },
    }
};
</script>

