import Vue from 'vue'
import VueRouter from 'vue-router'

import HomeView from '@/views/Home.vue'
import ArtistView from '@/views/Artist.vue'
import PortfolioView from '@/views/Portfolio.vue'
import OilView from '@/views/OilCollection.vue'
import GouacheView from '@/views/GouacheCollection.vue'
import ExhibitionsView from '@/views/Exhibitions.vue'
import ContactView from '@/views/Contact.vue'
import PolicyView from '@/views/PrivacyPolicy.vue'
import TermsView from '@/views/TermsCondition.vue'

Vue.use(VueRouter)

const routes = [
  {path: '/',name: 'home',component: HomeView},
  {path: '/work',name: 'paintings',component: PortfolioView},
  {path: '/work/oil-on-linen',name: 'oil',component: OilView},
  {path: '/work/gouache-on-paper',name: 'gouache',component: GouacheView},
  {path: '/cv',name: 'exhibitions',component: ExhibitionsView},
  {path: '/statement',name: 'artist',component: ArtistView},
  {path: '/contact',name: 'contact',component: ContactView},
  {path: '/privacy-policy',name: 'policy',component: PolicyView},
  {path: '/terms-and-conditions',name: 'terms',component: TermsView}
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
  scrollBehavior(to, from, savedPosition) {
    window.scrollTo(0, 0)
  }
})

export default router
